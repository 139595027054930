import React from "react"
import Layout from "../Components/Layout"
import Box from "../Components/Box2"

import SuccessIlliustration from "../assets/images/success-illiustration.jpg"

export default () => (
  <Layout>
    <div className="registration-success">
      <Box>
        <h1 className="tac">
          Sveikiname, tu sėkmingai užsiregistravai dalyvauti Nacionaliniame aplinkosaugos egzamine!{" "}
        </h1>

        <div className="tac text-container">
          <p>
            Patvirtink savo registraciją paspausdamas nuorodą į Tavo el. pašto dėžutę atkeliavusiame laiške.

            {/*
            Pradėk egzaminą spusteldamas nuorodą el. laiške, kuris
            jau tavo pašto dėžutėje arba  {" "}
            <a href="https://aplinkosaugosegzaminas.lrt.lt/exam-start">
              spustelk čia.
            </a>
            */}

          </p>
        </div>

      </Box>
    </div>
  </Layout>
)
